import React from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { getDashboardDetailActionThunk } from "redux/Dashboard/action";
import { selectDashboardDetails } from "redux/Dashboard/selector";
import { selectUserToken } from "redux/user/selector";

import Header from "components/Headers/Header";
import Loading from "components/Loading/Loading";

import { GiMoneyStack } from "react-icons/gi";
import { HiUsers } from "react-icons/hi";
import { BsBarChartSteps } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";

import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import "../assets/css/Dashboard.css";

import clearGridIcon from "../assets/img/brand/clear_grid_icon.svg";

function Dashboard() {
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const { dashboardDetails, isLoading } = useSelector(selectDashboardDetails);
  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const customersOrderChart = {
    options: {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 5)) {
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: dashboardDetails?.ordersByCustomer?.map((item) => {
        return item.firstName;
      }),
      datasets: [
        {
          label: "Orders Count",
          backgroundColor: "#696969d1",
          data: dashboardDetails?.ordersByCustomer?.map((item) => {
            return item.ordersCount;
          }),
          maxBarThickness: 15,
        },
      ],
    },
  };
  const ordersCatalogChart = {
    options: {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 5)) {
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: dashboardDetails?.ordersCatalog?.map((item) => {
        return item.title;
      }),
      datasets: [
        {
          label: "Orders Count",
          highlightStroke: "red",
          backgroundColor: "#696969d1",
          data: dashboardDetails?.ordersCatalog?.map((item) => {
            return item.ordersCount;
          }),
          maxBarThickness: 15,
        },
      ],
    },
  };

  React.useEffect(() => {
    if (token) {
      let requestParams = {
        start_date: startDate ? startDate[0] : null,
        end_date: endDate ? endDate[0] : null,
      };
      dispatch(getDashboardDetailActionThunk(requestParams));
    }
  }, [token]);
  const clearGridHandler = () => {
    if (startDate?.length && endDate?.length) {
      let requestParams = {
        start_date: null,
        end_date: null,
      };

      dispatch(getDashboardDetailActionThunk(requestParams));
    }
  };
  const fetchDashboardDataHandler = () => {
    if (startDate?.length && endDate?.length) {
      let requestParams = {
        start_date: startDate ? startDate[0] : null,
        end_date: endDate ? endDate[0] : null,
      };

      dispatch(getDashboardDetailActionThunk(requestParams));
    }
  };

  // React.useEffect(() => {
  //   if (startDate?.length && endDate?.length) {
  //     let requestParams = {
  //       start_date: startDate ? startDate[0] : null,
  //       end_date: endDate ? endDate[0] : null,
  //     };
  //     dispatch(getDashboardDetailActionThunk(requestParams));
  //   }
  // }, [endDate]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            {isLoading ? (
              <React.Fragment>
                <div className="post_error_loading">
                  <Loading />
                </div>
              </React.Fragment>
            ) : (
              <Card className="shadow" style={{ minHeight: "60vh" }}>
                <Col xs="12" md="6">
                  <h2 className="mx-3 mt-4">Dashboard</h2>
                </Col>
                <Row className="d-flex flex-row-reverse mb-2">
                  <Col xs="12" lg="6" className="mr-0  px-2">
                    <div className="d-flex">
                      <div className="d-flex align-items-center position-relative ml-2">
                        <Flatpickr
                          ref={startDateRef}
                          required={true}
                          value={startDate}
                          options={{
                            dateFormat: "m/d/Y",
                            disableMobile: true,
                            allowInput: false,
                            onClose: (selectedDates) => {
                              setStartDate(selectedDates);
                            },
                          }}
                          onChange={(e) => {
                            setStartDate(e);
                          }}
                          className="form-control ml-2"
                          placeholder="Start Date"
                        />
                        <img
                          src={clearGridIcon}
                          alt="clear grid"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!startDateRef?.current?.flatpickr) return;
                            startDateRef.current.flatpickr.clear();
                          }}
                          className="position-absolute "
                          style={{
                            right: "1rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center ml-2 position-relative">
                        <Flatpickr
                          ref={endDateRef}
                          required={true}
                          value={endDate}
                          options={{
                            dateFormat: "m/d/Y",
                            minDate: startDate ? new Date(startDate) : "",
                            disableMobile: true,
                            allowInput: false,
                            onClose: (selectedDates) => {
                              setEndDate(selectedDates);
                            },
                          }}
                          onChange={(e) => {
                            setEndDate(e);
                          }}
                          className=" form-control ml-2 "
                          placeholder="End Date"
                        />
                        <img
                          src={clearGridIcon}
                          alt="clear grid"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!endDateRef?.current?.flatpickr) return;
                            endDateRef.current.flatpickr.clear();
                          }}
                          className="position-absolute  "
                          style={{
                            right: "1rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <Button
                        onClick={fetchDashboardDataHandler}
                        disable={!startDate?.length || !endDate?.length}
                        style={{
                          backgroundColor: "dimgray",
                          color: "#fff",
                          // margin: "0 1rem ",
                          marginLeft: "1rem",
                          borderRadius: "0px",
                        }}
                      >
                        Search
                      </Button>
                      <Button
                        className="ml-3"
                        outline
                        color="warning"
                        size="sm"
                        style={{
                          // width: "15vh",
                          marginRight: "1rem",
                          padding: "3px 1rem",
                        }}
                        onClick={() => {
                          setEndDate("");
                          setStartDate("");
                          clearGridHandler();
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" style={{ marginTop: "5rem" }}>
                    <Row className="mx-4">
                      <Col lg="3">
                        <div className="my-1">
                          <Card className="card-stats mb-lg-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <Col>
                                    <CardTitle className="text-uppercase text-muted mb-0">
                                      Total Revenue
                                    </CardTitle>
                                  </Col>
                                  <Col>
                                    <span className="h2 font-weight-bold mb-0">
                                      {dashboardDetails?.money
                                        ? `$ ${dashboardDetails.money
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
                                              ","
                                            )}`
                                        : "$ 0.00"}
                                    </span>
                                  </Col>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                    <i>
                                      <GiMoneyStack />
                                    </i>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="my-1">
                          <Card className="card-stats mb-lg-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <Col>
                                    <CardTitle className="text-uppercase text-muted mb-0">
                                      Total Orders
                                    </CardTitle>
                                  </Col>
                                  <Col>
                                    <span className="h2 font-weight-bold mb-0">
                                      {dashboardDetails.ordersCount}
                                    </span>
                                  </Col>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                    <i>
                                      <FaChartBar />
                                    </i>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="my-1">
                          <Card className="card-stats mb-lg-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <Col>
                                    <CardTitle className="text-uppercase text-muted mb-0">
                                      Total Wholesalers
                                    </CardTitle>
                                  </Col>
                                  <Col>
                                    <span className="h2 font-weight-bold mb-0">
                                      {dashboardDetails.wholesalersCount}
                                    </span>
                                  </Col>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                    <i>
                                      <BsBarChartSteps />
                                    </i>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="my-1">
                          <Card className="card-stats mb-lg-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <Col>
                                    <CardTitle className="text-uppercase text-muted mb-0">
                                      Total Retailers
                                    </CardTitle>
                                  </Col>
                                  <Col>
                                    <span className="h2 font-weight-bold mb-0">
                                      {dashboardDetails.customersCount}
                                    </span>
                                  </Col>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                    <i>
                                      <HiUsers />
                                    </i>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  {/* <Col lg="8">
                    <Card className="my-2">
                      <CardBody style={{ height:'370px' }}>
                        Orders of Catalog
                        <Bar
                          data={ordersCatalogChart?.data}
                          options={ordersCatalogChart?.options}
                        />
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
                {/* <Row className="mb-4">
                  <Col lg="12">
                    <Card className="my-2">
                      <CardBody style={{ height:'370px' }}>
                        Orders By Wholesaler
                        <Bar
                          data={customersOrderChart?.data}
                          options={customersOrderChart?.options}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
              </Card>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
