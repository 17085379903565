import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";
import projectLogo from "../assets/img/logo/LogoCatalogBlack.png";

import ForgotPassword from "Pages/ForgotPassword";
import Login from "Pages/Login";
import VerifyOtp from "Pages/VerifyOtp";
import ResetPassword from "Pages/ResetPassword";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/* <AuthNavbar /> */}
        <div className="header bg-gradient-info py-7 py-lg-7">
          <Container>
            <div className="header-body text-center mb-0">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img
                    alt="Catalog of Perfums logo"
                    className="project-logo"
                    src={projectLogo}
                    style={{
                      width: "300px",
                      height: "250px",
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/forgotpassword" component={ForgotPassword} />
              <Route path="/auth/verifyotp" component={VerifyOtp} />
              <Route path="/auth/resetpassword" component={ResetPassword} />
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
