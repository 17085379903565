import axiosInstance from "./AxiosInstance";

export const generateAccessCodeAutomaticApi = (postData) =>
  axiosInstance.post("accesscode/generate", postData);

export const generateAccessCodeManualApi = (postData) =>
  axiosInstance.post("accesscode/add", postData);

export const getAccessCodeListApi = () => axiosInstance.get("accesscode/list");

export const updateWholesalerActiveStatusApi = (id) =>
  axiosInstance.get(`users/wholesaler/activate/${id}`);

export const updateWholesalerDeactiveStatusApi = (id) =>
  axiosInstance.get(`users/wholesaler/de-activate/${id}`);

export const getWholesalersAccesCodeApi = () =>
  axiosInstance.get("accesscode/wholesaler/list");

  export const getCatalogData = () =>
  axiosInstance.get("catalog/active/data");

  export const postCatalogData = (id, postData) =>
  axiosInstance.post(`catalog/${id}/accesscode/bind`, postData);
