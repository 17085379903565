import toast from "react-hot-toast";
import {
  changePasswordUserApi,
  forgotPasswordUserApi,
  getUsersListApi,
  resetPasswordUserApi,
  userDetailApi,
  verifyOtpUserApi,
} from "../../services/UserServices.js";
import { getLoggedInUserApi } from "services/UserServices";
import {
  FETCH_TOTAL_USERS_FAILURE,
  FETCH_TOTAL_USERS_REQUEST,
  FETCH_TOTAL_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_FORGET_PASSWORD_USER_OTP,
  RESET_IS_SIGNING,
  RESET_IS_SUBMITING,
  SELECTED_USER,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_FORGET_PASSWORD_USER_OTP,
  SET_IS_SIGNING,
  SET_IS_SUBMITING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
} from "./type";

export const setLoggedInUser = ({ user, token }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token },
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER,
  };
};
export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING,
  };
};
export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING,
  };
};

export const setIsSubmiting = () => {
  return {
    type: SET_IS_SUBMITING,
  };
};

export const resetIsSubmiting = () => {
  return {
    type: RESET_IS_SUBMITING,
  };
};

export const refreshState = ({ token, user }) => ({
  type: REFRESH_STATE,
  payload: { token, user },
});

export const setForgetpasswordUserEmail = (email) => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL,
    payload: email,
  };
};

export const resetForgetpasswordUserEmail = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL,
  };
};

export const setForgetpasswordUserOtp = (otp) => {
  return {
    type: SET_FORGET_PASSWORD_USER_OTP,
    payload: otp,
  };
};

export const resetForgetpasswordUserOtp = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_OTP,
  };
};

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};
export const fetchUserDetailRequest = () => {
  return {
    type: FETCH_USER_DETAILS_REQUEST,
  };
};
export const fetchUserDetailSuccess = (users) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: users,
  };
};

export const fetchUserDetailfailer = (error) => {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchUsersSuccess = (users, totalpage) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { users, totalpage },
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};
export const fetchTotalUsersRequest = () => {
  return {
    type: FETCH_TOTAL_USERS_REQUEST,
  };
};
export const fetchTotalUsersSuccess = (totalpage, totalusers) => {
  return {
    type: FETCH_TOTAL_USERS_SUCCESS,
    payload: { totalpage, totalusers },
  };
};

export const fetchTotalUsersFailure = (error) => {
  return {
    type: FETCH_TOTAL_USERS_FAILURE,
    payload: error,
  };
};

export const selecteduser = (user) => {
  return {
    type: SELECTED_USER,
    payload: user,
  };
};
export const fetchLoginUserThunkAction = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await getLoggedInUserApi({ email: email, password });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("Logged in successfully.");
      localStorage.setItem("adt_access_token", data.data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      dispatch(
        setLoggedInUser({
          token: data.data.accessToken,
          user: data.data.user,
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const forgetPasswordEmailThunkAction = (email, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSubmiting());
      const { data } = await forgotPasswordUserApi({
        email: email,
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("Please verify the OTP send to your email.");
      dispatch(setForgetpasswordUserEmail(email));
      onSuccess();
      dispatch(resetIsSubmiting());
    } catch (error) {
      dispatch(resetIsSubmiting());
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const verifyOtpThunkAction = ({ code, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await verifyOtpUserApi({ otp: code, email });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("OTP verified successfully.");
      dispatch(setForgetpasswordUserOtp(code));
      onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const resetPasswordThunkAction = (
  { password, email, otp },
  onSuccess
) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password, otp });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("Your password has been reset successfully.");
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
};
export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem("adt_access_token");
      dispatch(setLoggedOutUser());
      localStorage.removeItem("user");
      toast.success("Logged out successfully.");
      onSuccess();
    } catch (error) {}
  };
};

export const changePasswordThunkAction = async ({ password }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await changePasswordUserApi({ password });
      toast.success("Your password has been changed successfully.");

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
};

export const fetchUsersthunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersRequest());
      const { data } = await getUsersListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(
        fetchUsersSuccess(
          data.data.data,
          data.data.total / params.limit,
          data.data.total
        )
      );

      onSuccess();
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
      onError(error.response?.data?.message || error.message);
    }
  };
};

export const fetchTotalUsersthunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await getUsersListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(
        fetchTotalUsersSuccess(data.data.total / params.limit, data.data.data)
      );

      onSuccess();
    } catch (error) {
      dispatch(fetchTotalUsersFailure(error.message));
      onError(error.response?.data?.message || error.message);
    }
  };
};

export const userDetailThunkAction = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserDetailRequest());
      const { data } = await userDetailApi(userId);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchUserDetailSuccess(data.data));
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
};
