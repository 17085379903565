import React from "react";
import { useHistory } from "react-router-dom";

import { Button } from "reactstrap";

function GoBackButton() {
  let history = useHistory();

  return (
    <>
      <Button
        style={{
          background: "transparent",
          borderTopLeftRadius: "100px",
          borderBottomLeftRadius: "100px",
          borderTopRightRadius: "100px",
          borderBottomRightRadius: "100px",
          boxShadow: "none",
          border: "1px solid #e3e3e3",
        }}
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
        Back
      </Button>
    </>
  );
}

export default GoBackButton;
