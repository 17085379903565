import toast from "react-hot-toast";
import { getOrderHistoryListApi } from "services/OrdersService";

import {
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_TOTAL_ORDER_HISTORY_FAILURE,
  FETCH_TOTAL_ORDER_HISTORY_SUCCESS,
} from "./type";

export const fetchOrderHistoryRequest = () => {
  return {
    type: FETCH_ORDER_HISTORY_REQUEST,
  };
};
export const fetchOrderHistorySuccess = (data, totalPages) => {
  return {
    type: FETCH_ORDER_HISTORY_SUCCESS,
    payload: { data, totalPages },
  };
};

export const fetchOrderHistoryFailure = (error) => {
  return {
    type: FETCH_ORDER_HISTORY_FAILURE,
    payload: error,
  };
};
export const fetchTotalOrderHistorySuccess = (data) => {
  return {
    type: FETCH_TOTAL_ORDER_HISTORY_SUCCESS,
    payload: { data },
  };
};

export const fetchTotalOrderHistoryFailure = (error) => {
  return {
    type: FETCH_TOTAL_ORDER_HISTORY_FAILURE,
    payload: error,
  };
};

export const fetchOrderHistoryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderHistoryRequest());
      const { data } = await getOrderHistoryListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(
        fetchOrderHistorySuccess(
          data.data.orders,
          data.data.ordersCount / params.limit
        )
      );

      onSuccess();
    } catch (error) {
      dispatch(fetchOrderHistoryFailure(error.message));
      onError(error.response?.data?.message || error.message);
    }
  };
};
export const fetchTotalOrderHistoryThunkAction = (
  params,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      const { data } = await getOrderHistoryListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(fetchTotalOrderHistorySuccess(data.data.orders));

      onSuccess();
    } catch (error) {
      dispatch(fetchTotalOrderHistoryFailure(error.message));
      onError(error.response?.data?.message || error.message);
    }
  };
};
