import Dashboard from "Pages/Dashboard";
import GenerateAccessCode from "Pages/GenerateAccessCode";
import OrderHistory from "Pages/OrderHistory";
import UsersPage from "Pages/UsersPage";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-users text-primary",
    component: UsersPage,
    layout: "/admin",
  },
  {
    path: "/generateAccessCode",
    name: "Access Code",
    icon: "fa fa-terminal text-primary",
    component: GenerateAccessCode,
    layout: "/admin",
  },
  {
    path: "/order/history",
    name: "Order History",
    icon: "fa fa-history text-primary",
    component: OrderHistory,
    layout: "/admin",
  },
];
export default routes;
