import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { fetchLoginUserThunkAction } from "redux/user/action";
import { selectUser } from "redux/user/selector";

import usePasswordShow from "hooks/usePasswordShow";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const dispatch = useDispatch();

  const history = useHistory();
  const { isSigning } = useSelector(selectUser);
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();

  const navigateToHome = () => {
    history.replace("/admin/dashboard");
  };

  const submitHadler = (e) => {
    e.preventDefault();
    if (password === "" || email === "") {
      toast.error("All fields are required!");
      return;
    }
    if (password.trim() === "" || email.trim() === "") {
      toast.error("White spaces are not allowed!");
      return;
    }

    dispatch(
      fetchLoginUserThunkAction(
        { email: email, password: password },
        navigateToHome
      )
    );
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-4">
            <div className="text-center  mb-4">
              <h3 className="text-dark text-center">
                Sign In With Credentials
              </h3>
            </div>
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={type}
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    style={{ cursor: "pointer", opacity: "0.5" }}
                    onClick={togglePasswordVisiblity}
                  >
                    <InputGroupText>
                      {passwordShown ? (
                        <img
                          alt="hidepassword"
                          src={
                            require("assets/img/theme/hide-password-icon.png")
                              .default
                          }
                        />
                      ) : (
                        <img
                          alt="showpassword"
                          src={
                            require("assets/img/theme/show-password-icon.png")
                              .default
                          }
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="dark"
                  type="submit"
                  disabled={isSigning ? true : false}
                >
                  {isSigning ? "Signing in..." : "Sign in"}
                </Button>
              </div>
              <Row className="mt-3">
                <Col xs="12" className="text-right">
                  <NavLink
                    to="/auth/forgotpassword"
                    tag={Link}
                    className="nav-link-inner--text"
                    style={{ color: "#000" }}
                  >
                    <small>Forgot Password?</small>
                  </NavLink>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
