import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import AccessCodeTable from 'components/AccessCodeTable/AccessCodeTable';
import WholesalerAccessCodeTable from 'components/WholesalerAccessCodeTable/WholesalerAccessCodeTable';
import Header from 'components/Headers/Header';
import Loading from 'components/Loading/Loading';
import '../components/AccessCodeTable/style.css';

import { fetchAccessCodesThunkAction } from 'redux/AccessCode/action';
import { generateAccessCodeManualThunkAction } from 'redux/AccessCode/action';
import { generateAccessCodeAutomaticThunkAction } from 'redux/AccessCode/action';
import { selectAccessCode } from 'redux/AccessCode/selector';

import useViewport from 'hooks/useViewport';
import moment from 'moment';
import { fetchWholesalerAccessCodesThunkAction } from 'redux/AccessCode/action';
import { downloadFile } from 'components/downloadCsvFile/DownloadCSV';
import copy from 'copy-to-clipboard';
const urlData = process.env.REACT_APP_WEB_BASE_URL;

function GenerateAccessCode() {
  const { accessCodes, isLoading } = useSelector(selectAccessCode);
  const { wholesalerAccessCodes } = useSelector(selectAccessCode);
  const [codeList, setCodeList] = React.useState([]);
  const [limit, setLimit] = React.useState('');
  const [accessCode, setAccessCode] = React.useState('');
  const [generatedAccessCode, setGeneratedAccessCode] = React.useState([]);

  const dispatch = useDispatch();
  const { width } = useViewport();
  const [toggle, setToggle] = React.useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const onUpdate = () => {
    dispatch(fetchAccessCodesThunkAction());
  };

  const submitAutoCodeGenerateHadler = (e) => {
    e.preventDefault();
    dispatch(
      generateAccessCodeAutomaticThunkAction(
        { limit: parseInt(limit) },
        onUpdate
      )
    );

    // onUpdate();

    setLimit('');
  };
  const submitManualCodeGenerateHadler = (e) => {
    e.preventDefault();
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/.test(accessCode)) {
      toast.error(
        'Access code must contain 6 characters including uppercase, lowercase and digits.'
      );
      return;
    }
    dispatch(
      generateAccessCodeManualThunkAction({ accessCode: accessCode }, onUpdate)
    );

    // onUpdate();
    setAccessCode('');
  };
  React.useEffect(() => {
    dispatch(fetchAccessCodesThunkAction());
    dispatch(fetchWholesalerAccessCodesThunkAction());
  }, [dispatch]);

  React.useEffect(() => {
    setCodeList(accessCodes);
  }, [accessCodes, limit, accessCode]);

  React.useEffect(() => {
    const formattedWholesalerAccessCodes = wholesalerAccessCodes
      ?.map((code) => {
        return code.accessCodes;
      })
      ?.flat();

    setGeneratedAccessCode(formattedWholesalerAccessCodes);
  }, [wholesalerAccessCodes]);

  const exportWholesalerCodesCsv = async () => {
    let headers = ['Date,AccessCode'];
    let allTheAccessCodes = [];

    wholesalerAccessCodes.forEach((user) => {
      user.accessCodes.forEach((accessCode) => {
        allTheAccessCodes.push(accessCode);
      });
    });
    let updateArray = [];

    allTheAccessCodes.forEach((code) => {
      if (code.accessCodes.length > 1) {
        let allAccessCodes = code.accessCodes;
        allAccessCodes.forEach((item) => {
          updateArray.push({
            date: code.date,
            code: item,
          });
        });
      } else {
        updateArray.push({
          date: code.date,
          code: code.accessCodes[0],
        });
      }
    });
    let usersCsv = await updateArray.reduce((acc, data) => {
      const { date, code } = data;
      acc.push([moment(date).format('MM/DD/YYYY [at] h:mm A'), code].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'AccessCodes.csv',
      fileType: 'text/csv',
    });
  };
  const exportGeneratedCodesCsv = async () => {
    let headers = ['Date,AccessCode'];

    let updateArray = [];
    accessCodes.forEach((code) => {
      if (code.accessCodes.length > 1) {
        let allAccessCodes = code;
        allAccessCodes.accessCodes.forEach((item) => {
          updateArray.push({
            date: code.date,
            code: item,
          });
        });
      } else {
        updateArray.push({
          date: code.date,
          code: code.accessCodes[0],
        });
      }
    });
    let usersCsv = await updateArray.reduce((acc, data) => {
      const { date, code } = data;
      acc.push([moment(date).format('MM/DD/YYYY [at] h:mm A'), code].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'GeneratedAccessCodes.csv',
      fileType: 'text/csv',
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 " xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h2 className="mb-0">Access Codes</h2>
                  </Col>
                  <Col xs="12" className="text-left mt-4">
                    <label htmlFor="bindAccess">Bind AccessCode</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        id="bindAccess"
                        type="text"
                        value={urlData}
                        disabled
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <InputGroupText>
                          <i
                            className="far fa-copy"
                            onClick={() =>
                              copy(urlData)
                            }
                          ></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>

              <React.Fragment>
                {generatedAccessCode.length > 0 ? (
                  <React.Fragment>
                    <div
                      className="col mb-2"
                      style={
                        width < 768
                          ? {}
                          : {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }
                      }
                    >
                      <Card
                        style={
                          width < 850 ? { width: '100%' } : { width: '79vh' }
                        }
                        className="bg-secondary shadow border-0 "
                      >
                        <div className="access_code_table_wrapper">
                          <table className="table grid table-condensed table-striped wholesaler-scrollable mb-3">
                            <thead className="text-dark">
                              <tr>
                                <th
                                  scope="col"
                                  align="left"
                                  style={{
                                    width: '20%',
                                  }}
                                >
                                  {' '}
                                  Access Codes
                                </th>
                                <th
                                  scope="col"
                                  align="right"
                                  style={{
                                    width: '80%',
                                    textAlignLast: 'end',
                                  }}
                                >
                                  {' '}
                                  <Button
                                    size="sm"
                                    onClick={exportWholesalerCodesCsv}
                                    style={{
                                      backgroundColor: 'dimgray',
                                      color: '#fff',
                                      // margin: "0 1rem ",
                                      marginLeft: '1rem',
                                      borderRadius: '0px',
                                    }}
                                  >
                                    Export CSV
                                  </Button>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {generatedAccessCode?.map((item, index) => {
                                return (
                                  <WholesalerAccessCodeTable
                                    key={index}
                                    index={index + 1}
                                    // item={item.name}
                                    item={moment(item.date).format(
                                      'MM/DD/YYYY [at] h:mm A'
                                    )}
                                    value={
                                      item.accessCodes
                                      // wholesalerAccessCodes[`${item.accessCodes}`]
                                    }
                                  />
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="post_error_loading">
                      <h2>Data not found</h2>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Card>
          </Col>
          <Col className="order-xl-1 " xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h2 className="mb-0">Generate Access Code</h2>
                  </Col>
                </Row>
              </CardHeader>

              <Row>
                <div
                  className="col mb-2"
                  style={
                    width < 768
                      ? {}
                      : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }
                  }
                >
                  <Card
                    style={width < 850 ? { width: '100%' } : { width: '80vh' }}
                    className="bg-secondary shadow border-0"
                  >
                    <CardBody className="px-lg-5 py-lg-2">
                      <Form
                        role="form"
                        onSubmit={submitManualCodeGenerateHadler}
                      >
                        <FormGroup>
                          <Row className="text-center rounded-bottom">
                            <Col lg="9" className="my-2 col-9 text-center">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-ruler-pencil" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Create Custom Code"
                                  type="text"
                                  required
                                  name="accessCode"
                                  value={accessCode}
                                  onChange={(e) =>
                                    setAccessCode(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col lg="2">
                              <Button
                                className="mt-2"
                                color="primary"
                                outline
                                type="submit"
                              >
                                Generate
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <Row>
                <div
                  className="col mb-2"
                  style={
                    width < 768
                      ? {}
                      : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }
                  }
                >
                  <Card
                    style={width < 850 ? { width: '100%' } : { width: '80vh' }}
                    className="bg-secondary shadow border-0"
                  >
                    <CardBody className="px-lg-5 py-lg-2">
                      <Form role="form" onSubmit={submitAutoCodeGenerateHadler}>
                        <FormGroup>
                          <Row className="text-center rounded-bottom">
                            <Col lg="9" className="my-2 col-9 text-center">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fa fa-list-ol" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Quantity"
                                  type="number"
                                  required
                                  name="limit"
                                  min={1}
                                  value={limit}
                                  onChange={(e) => setLimit(e.target.value)}
                                />
                              </InputGroup>
                            </Col>
                            <Col lg="2">
                              <Button
                                className="mt-2"
                                color="primary"
                                outline
                                type="submit"
                              >
                                Generate
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Row>

              <Row>
                <div
                  className="col mb-2"
                  style={
                    width < 768
                      ? {}
                      : {
                          display: 'flex',
                          // alignItems: "center",
                          justifyContent: 'center',
                        }
                  }
                >
                  {isLoading ? (
                    <React.Fragment>
                      <div className="post_error_loading">
                        <Loading />
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {Object.keys(accessCodes).length > 0 ? (
                        <React.Fragment>
                          <Card
                            style={
                              width < 850
                                ? { width: '100%' }
                                : { width: '80vh', height: '39vh' }
                            }
                            className="bg-secondary shadow border-0 "
                          >
                            <div className="access_code_table_wrapper">
                              <table className="table table-condensed table-striped mb-3">
                                <thead className="text-dark">
                                  <tr>
                                    <th scope="col">Generated Access Codes</th>

                                    <th
                                      scope="col"
                                      align="right"
                                      style={{
                                        width: '80%',
                                        textAlignLast: 'end',
                                      }}
                                    >
                                      {' '}
                                      <Button
                                        size="sm"
                                        onClick={exportGeneratedCodesCsv}
                                        style={{
                                          backgroundColor: 'dimgray',
                                          color: '#fff',
                                          // margin: "0 1rem ",
                                          marginLeft: '1rem',
                                          borderRadius: '0px',
                                        }}
                                      >
                                        Export CSV
                                      </Button>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {codeList?.map((item, index) => (
                                    <AccessCodeTable
                                      key={index}
                                      index={index + 1}
                                      item={moment(item.date).format(
                                        'MM/DD/YYYY [at] h:mm A'
                                      )}
                                      value={item}
                                    />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Card>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="post_error_loading">
                            <h2>Data not found</h2>
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default GenerateAccessCode;
