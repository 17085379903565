/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Container, Row, Col, Button, FormGroup } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from 'components/Headers/Header.jsx';

import Loading from 'components/Loading/Loading';

import { getCatalogData } from 'services/GenerateAccessCodeService';
import { catalogAccessCodeGenerateThunkAction } from 'redux/AccessCode/action';

const NewCodeGeneration = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isSigning, setIsSigning] = React.useState(false);

  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      accessCode: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address.'),
      accessCode: Yup.string().required('Access Code is required'),
    }),
    onSubmit: (values) => {
      function success() {
        setIsSigning(false);
        formik.resetForm();
      }

      setIsSigning(true);
      dispatch(
        catalogAccessCodeGenerateThunkAction(
          data?.id,
          { email: values.email, accessCode: values.accessCode },
          success
        )
      );
    },
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getCatalogData();

        setData(data.data);

        setLoading(false);
        setError(null);
      } catch (error) {
        setLoading(false);
        setError(
          error.response?.data?.message || error.message || 'error occured.'
        );
        console.log(error?.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow catalog_accesscode_container">
              {loading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loading />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src={data?.imageUrl}
                      alt="catalog"
                      className="catalog_accesscode_image_banner"
                    />

                    <h2 className="mt-5">{data?.title}</h2>

                    <form
                      style={{
                        maxWidth: '500px',
                        margin: 'auto',
                      }}
                      onSubmit={formik.handleSubmit}
                      className="sign_up_form"
                    >
                      <Row className="px-lg-5 px-md-4 px-3 py-3">
                        <Col lg="12">
                          <FormGroup>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email"
                              id="email"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger mt-2 text-left">
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Access Code"
                                id="accessCode"
                                name="accessCode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accessCode}
                              />
                            </div>
                            {formik.touched.accessCode &&
                            formik.errors.accessCode ? (
                              <div className="text-danger mt-2 text-left">
                                {formik.errors.accessCode}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col sm={12}>
                          <Button
                            disabled={!formik.isValid || isSigning}
                            color="primary"
                            type="submit"
                          >
                            {isSigning ? 'Submitting...' : 'Submit'}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            </Card>
            <div style={{ textAlign: 'center', marginTop: '20px' }} >
              <h4>Click below button to redirect to the mobile app.</h4>
              <Button
                color="primary"
                onClick={(e) => {
                  window.open("catalogofperfums://home", "_blank");
                }}
              >Redirect to App</Button>
              <h4>Please register and verify your account before accessing the code from the app.</h4>
              <a
              target="_blank"
              href='https://play.google.com/store/apps/details?id=com.alphadesigntech.catalogofperfumes'>
                <img
                  height={75}
                  width={200}
                  src={require("../assets/img/logo/play-store.png").default}
                  alt="play-store"
                />
              </a>
              <a
              target="_blank"
              href='https://apps.apple.com/ca/app/catalog-of-perfums/id1643652281'>
                <img
                  height={83}
                  width={200}
                  src={require("../assets/img/logo/app-store.png").default}
                  alt="play-store"
                />
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NewCodeGeneration;
