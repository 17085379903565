export const FETCH_ORDER_HISTORY_REQUEST = "FETCH_ORDER_HISTORY_REQUEST";
export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";
export const FETCH_ORDER_HISTORY_FAILURE = "FETCH_ORDER_HISTORY_FAILURE";

export const FETCH_TOTAL_ORDER_HISTORY_REQUEST =
  "FETCH_TOTAL_ORDER_HISTORY_REQUEST";
export const FETCH_TOTAL_ORDER_HISTORY_SUCCESS =
  "FETCH_TOTAL_ORDER_HISTORY_SUCCESS";
export const FETCH_TOTAL_ORDER_HISTORY_FAILURE =
  "FETCH_TOTAL_ORDER_HISTORY_FAILURE";
