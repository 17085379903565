import { combineReducers } from "redux";

import userReducer from "./user/reducer";
import accessCodeReducer from "./AccessCode/reducer";
import dashBoardReducer from "./Dashboard/reducer";
import orderHistoryReducer from "./orders/reducer";


const rootReducer = combineReducers({
  user: userReducer,
  accessCode: accessCodeReducer,
  dashboard: dashBoardReducer,
  orders: orderHistoryReducer,
});

export default rootReducer;
