/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Col,
  Button,
} from 'reactstrap';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';

import Header from 'components/Headers/Header.jsx';
import { getColumns } from 'components/TableColumn/OrderHistoryColumn';
import Paginate from 'components/Pagination/Paginate';
import Loading from 'components/Loading/Loading';

import { selectUserToken } from 'redux/user/selector';

import '../assets/css/UsersPage.css';
import clearGridIcon from '../assets/img/brand/clear_grid_icon.svg';
import { fetchOrderHistoryThunkAction } from 'redux/orders/action';
import { selectOrderHistoryData } from 'redux/orders/selector';
import moment from 'moment';
import { fetchTotalOrderHistoryThunkAction } from 'redux/orders/action';
import { downloadFile } from 'components/downloadCsvFile/DownloadCSV';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const OrderHistory = () => {
  const [usersData, setUsersData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();
  const [startDate, setStartDate] = React.useState([]);
  const [endDate, setEndDate] = React.useState([]);
  const [sort, setSort] = React.useState(null);

  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const dispatch = useDispatch();
  const { ordersData, isLoading, totalPages, totalOrdersData } = useSelector(
    selectOrderHistoryData
  );

  const token = useSelector(selectUserToken);

  React.useEffect(() => {
    setUsersData(ordersData);
  }, [ordersData, ordersData?.length]);

  const clearGridHandler = () => {
    if (startDate?.length && endDate?.length) {
      let requestParams = {
        start_date: null,
        end_date: null,
      };

      dispatch(fetchOrderHistoryThunkAction(requestParams, onSuccess, onError));
    }
  };
  const data = React.useMemo(() => usersData, [usersData]);
  const columns = React.useMemo(() => getColumns(index), [index]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        pageSize: INITIAL_PAGE_SIZE,
        sortBy: [],
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPages),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setPageSize,
    setSortBy,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const fetchHistoryDataHandler = () => {
    let requestParams = {
      page: pageIndex,
      limit: pageSize,
      order_by: sort?.sortBy,
      order: sort?.sortDirection,
    };

    if (startDate.length && endDate.length) {
      requestParams.startDate = startDate[0];
      requestParams.endDate = endDate[0];
    }
    dispatch(fetchOrderHistoryThunkAction(requestParams, onSuccess, onError));
  };

  React.useEffect(() => {
    if (token) {
      fetchHistoryDataHandler();
    }
  }, [pageIndex, pageSize, token, sort]);

  const fetchTotalOrderHistoryDataHandler = () => {
    let requestParams = {
      page: 0,
    };

    if (startDate.length && endDate.length) {
      requestParams.startDate = startDate[0];
      requestParams.endDate = endDate[0];
    }
    dispatch(
      fetchTotalOrderHistoryThunkAction(requestParams, onSuccess, onError)
    );
  };

  React.useEffect(() => {
    if (token) {
      fetchTotalOrderHistoryDataHandler();
    }
  }, [pageIndex, pageSize, token, sort]);
  const exportToCsv = async () => {
    let headers = [
      'Name,Title,Purchased On,Expires On,Transaction Id,Transaction Amount,Quantity,Status',
    ];
    let usersCsv = await totalOrdersData.reduce((acc, user) => {
      const {
        orderBy,
        catalog,
        createdAt,
        expirationDate,
        transactionId,
        transactionAmount,
        qty,
        isExpired,
      } = user;
      acc.push(
        [
          orderBy.firstName,
          catalog.title,
          moment(createdAt).format('MM/DD/YYYY [at] h:mm A'),
          moment(expirationDate).format('MM/DD/YYYY [at] h:mm A'),
          transactionId,
          '$ ' + transactionAmount,
          qty,
          isExpired ? 'Expired' : 'Active',
        ].join(',')
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'OrderHistory.csv',
      fileType: 'text/csv',
    });
  };
  const updateAfterSerchHandler = () => {
    if (pageIndex === 0) {
      fetchHistoryDataHandler();
    } else {
      gotoPage(0);
    }
    fetchTotalOrderHistoryDataHandler();
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      if (moment(startDate[0]).diff(moment(endDate[0])) > 0) {
        setEndDate([]);
        // updateAfterSerchHandler();
      }
    }
    if (startDate.length === 0) {
      endDateRef.current.flatpickr.clear();
      setEndDate([]);
      updateAfterSerchHandler();
    }
  }, [startDate]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="12" md="6">
                    <h2 className="mb-0">Order History</h2>
                  </Col>
                  <Col xs="12" className="mr-0">
                    <Row className="d-flex flex-row-reverse mb-2">
                      <Col xs="12" md="2" className="mr-0">
                        <Button
                          onClick={exportToCsv}
                          style={{
                            backgroundColor: 'dimgray',
                            color: '#fff',
                            // margin: "0 1rem ",
                            marginLeft: '1rem',
                            borderRadius: '0px',
                          }}
                        >
                          Export CSV
                        </Button>
                      </Col>
                      <Col xs="12" lg="6" className="mr-0  px-2">
                        <div className="d-flex">
                          <div className="d-flex align-items-center position-relative ml-2">
                            <Flatpickr
                              ref={startDateRef}
                              required={true}
                              value={startDate}
                              options={{
                                dateFormat: 'm/d/Y',
                                disableMobile: true,
                                allowInput: false,
                                onClose: (selectedDates) => {
                                  setStartDate(selectedDates);
                                },
                              }}
                              onChange={(e) => {
                                setStartDate(e);
                              }}
                              className="form-control ml-2"
                              placeholder="Start Date"
                            />
                            <img
                              src={clearGridIcon}
                              alt="clear grid"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!startDateRef?.current?.flatpickr) return;
                                startDateRef.current.flatpickr.clear();
                              }}
                              className="position-absolute "
                              style={{
                                right: '1rem',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center ml-2 position-relative">
                            <Flatpickr
                              ref={endDateRef}
                              required={true}
                              value={endDate}
                              options={{
                                dateFormat: 'm/d/Y',
                                minDate: startDate ? new Date(startDate) : '',
                                disableMobile: true,
                                allowInput: false,
                                onClose: (selectedDates) => {
                                  setEndDate(selectedDates);
                                },
                              }}
                              onChange={(e) => {
                                setEndDate(e);
                              }}
                              className=" form-control ml-2 "
                              placeholder="End Date"
                            />
                            <img
                              src={clearGridIcon}
                              alt="clear grid"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!endDateRef?.current?.flatpickr) return;
                                endDateRef.current.flatpickr.clear();
                              }}
                              className="position-absolute  "
                              style={{
                                right: '1rem',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          <Button
                            onClick={() => {
                              updateAfterSerchHandler();
                            }}
                            disabled={!startDate?.length || !endDate?.length}
                            style={{
                              backgroundColor: 'dimgray',
                              color: '#fff',
                              marginLeft: '1rem',
                              borderRadius: '0px',
                            }}
                          >
                            Search
                          </Button>
                          <Button
                            className="ml-3"
                            outline
                            color="warning"
                            size="sm"
                            style={{
                              // width: "15vh",
                              marginRight: '1rem',
                              padding: '3px 1rem',
                            }}
                            onClick={() => {
                              setEndDate('');
                              setStartDate('');
                              clearGridHandler();
                            }}
                          >
                            Clear All
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loading />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table
                        {...getTableProps()}
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          {headerGroups.map((headerGroup) => (
                            <tr
                              {...headerGroup.getHeaderGroupProps()}
                              key={headerGroup}
                            >
                              {headerGroup.headers.map((column) => (
                                <th
                                  key={column.id}
                                  onClick={() => {
                                    if (!column.disableSortBy) {
                                      const desc =
                                        column.isSortedDesc === true
                                          ? undefined
                                          : column.isSortedDesc === false
                                          ? true
                                          : false;
                                      setSort({
                                        sortBy: column.id,
                                        sortDirection: desc ? 'desc' : 'asc',
                                      });
                                      setSortBy([{ id: column.id, desc }]);
                                    }
                                  }}
                                  {...column.getHeaderProps()}
                                  style={
                                    column.disableSortBy === true
                                      ? null
                                      : { cursor: 'pointer' }
                                  }
                                >
                                  {column.render('Header')}
                                  <span>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="fas fa-arrow-down ml-2"></i>
                                      ) : (
                                        <i className="fas fa-arrow-up ml-2"></i>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} key={row.id}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {!error && usersData?.length === 0 && (
                        <div className="no_posts">No orders found.</div>
                      )}
                      {usersData && usersData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <div className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                              <div>
                                <Input
                                  type="select"
                                  style={{
                                    width: '65px',
                                    margin: '10px',
                                  }}
                                  value={pageSize}
                                  onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                  }}
                                >
                                  {[5, 10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                      {pageSize}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="mx-2">
                                  Page{' '}
                                  <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                  </strong>{' '}
                                </span>
                                <Paginate
                                  pageIndex={pageIndex}
                                  pageCount={pageCount}
                                  previousPage={previousPage}
                                  nextPage={nextPage}
                                  gotoPage={gotoPage}
                                />
                              </div>
                            </div>
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderHistory;
