import React from "react";
import { Button } from "reactstrap";

function TooltipIcon(props) {
  const { target, onclickHandler, iconClass, label, role } = props;
  return (
    <>
      {role === "retailer" ? (
        <Button outline id={target} color="success" size="sm" disabled>
          <i className={iconClass}></i>
          Activated
        </Button>
      ) : (
        (label === "Active" && (
          <Button
            outline
            id={target}
            color="success"
            onClick={onclickHandler}
            size="sm"
          >
            <i className={iconClass}></i>
            {label}
          </Button>
        )) || (
          <Button
            outline
            id={target}
            color="danger"
            onClick={onclickHandler}
            size="sm"
          >
            <i className={iconClass}></i>
            {label}
          </Button>
        )
      )}
    </>
  );
}

export default TooltipIcon;
