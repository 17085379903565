export const SET_LOGGEDIN_USER = "SET_USER";
export const SET_LOGGEDOUT_USER = "SET_LOGGEDOUT_USER";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const FETCH_TOTAL_USERS_REQUEST = "FETCH_TOTAL_USERS_REQUEST";
export const FETCH_TOTAL_USERS_SUCCESS = "FETCH_TOTAL_USERS_SUCCESS";
export const FETCH_TOTAL_USERS_FAILURE = "FETCH_TOTAL_USERS_FAILURE";

export const REFRESH_STATE = "REFRESH_STATE";

export const SET_FORGET_PASSWORD_USER_EMAIL = "SET_FORGET_PASSWORD_USER_EMAIL";
export const RESET_FORGET_PASSWORD_USER_EMAIL =
  "RESET_FORGET_PASSWORD_USER_EMAIL";

export const SET_IS_SIGNING = "SET_IS_SIGNING";
export const RESET_IS_SIGNING = "RESET_IS_SIGNING";

export const SET_IS_SUBMITING = "SET_IS_SUBMITING";
export const RESET_IS_SUBMITING = "RESET_IS_SUBMITING";

export const SET_FORGET_PASSWORD_USER_OTP = "SET_FORGET_PASSWORD_USER_OTP";
export const RESET_FORGET_PASSWORD_USER_OTP = "RESET_FORGET_PASSWORD_USER_OTP";

export const SELECTED_USER = "SELECTED_USER";

export const FETCH_USER_DETAILS_REQUEST = "FETCH_USER_DETAILS_REQUEST";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";
