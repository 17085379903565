import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { loggingOutUserThunkAction } from "redux/user/action";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import defaultLogo from "../../assets/img/theme/admin-logo.png";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const navigateToHome = () => {
    history.replace("/auth/login");
  };
  const logoutHandler = () => {
    dispatch(loggingOutUserThunkAction(navigateToHome));
  };
  const navigateToResetPasswordHandler = () => {
    history.push("/admin/changepassword");
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          ></Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="profile logo" src={defaultLogo} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block"></Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>

                <DropdownItem onClick={navigateToResetPasswordHandler}>
                  <i className="ni ni-lock-circle-open"></i>
                  <span>Change Password</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logoutHandler}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
