import { getDashboardDetailsApi } from "services/DashboardServices";
import {
  FETCH_DASHBOARD_DETAILS_FAILURE,
  FETCH_DASHBOARD_DETAILS_REQUEST,
  FETCH_DASHBOARD_DETAILS_SUCCESS,
} from "./type";

export const fetchDashboardDetailsRequest = () => {
  return {
    type: FETCH_DASHBOARD_DETAILS_REQUEST,
  };
};

export const fetchDashboardDetailsSuccess = (data) => {
  return {
    type: FETCH_DASHBOARD_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchDashboardDetailsFailure = (error) => {
  return {
    type: FETCH_DASHBOARD_DETAILS_FAILURE,
    payload: error,
  };
};

export const getDashboardDetailActionThunk = (params) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardDetailsRequest());
      const { data } = await getDashboardDetailsApi(params);
      dispatch(fetchDashboardDetailsSuccess(data.data));
      if (data.success !== true) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch(fetchDashboardDetailsFailure(error.message));
    }
  };
};
