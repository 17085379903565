import axiosInstance from './AxiosInstance';
import moment from 'moment';

export const getOrderHistoryListApi = (prms) =>
  axiosInstance.get("users/order/list", {
    params: {
      limit: prms.limit,
      page: prms?.page || 0,
      start_date: prms?.startDate ? moment
        .utc(moment(prms?.startDate).startOf("day"))
        .format() : undefined,
      end_date: prms?.endDate ?
        moment.utc(moment(prms?.endDate).endOf("day")).format() :  undefined,
      order_by: prms?.order_by,
      order: prms?.order,
    },
  });
