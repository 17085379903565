export const FETCH_ACCESS_CODE_REQUEST = "FETCH_ACCESS_CODE_REQUEST";
export const FETCH_ACCESS_CODE_SUCCESS = "FETCH_ACCESS_CODE_SUCCESS";
export const FETCH_ACCESS_CODE_FAILURE = "FETCH_ACCESS_CODE_FAILURE";

export const GENERATE_ACCESS_CODE_REQUEST = "GENERATE_ACCESS_CODE_REQUEST";
export const GENERATE_ACCESS_CODE_SUCCESS = "GENERATE_ACCESS_CODE_SUCCESS";
export const GENERATE_ACCESS_CODE_FAILURE = "GENERATE_ACCESS_CODE_FAILURE";

export const GENERATE_ACCESS_CODE = "GENERATE_ACCESS_CODE";
export const GENERATE_MANUAL_ACCESS_CODE = "GENERATE_MANUAL_ACCESS_CODE";

export const FETCH_WHOLESALER_ACCESS_CODE_REQUEST =
  "FETCH_WHOLESALER_ACCESS_CODE_REQUEST";
export const FETCH_WHOLESALER_ACCESS_CODE_SUCCESS =
  "FETCH_WHOLESALER_ACCESS_CODE_SUCCESS";
export const FETCH_WHOLESALER_ACCESS_CODE_FAILURE =
  "FETCH_WHOLESALER_ACCESS_CODE_FAILURE";
