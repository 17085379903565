const {
  FETCH_DASHBOARD_DETAILS_FAILURE,
  FETCH_DASHBOARD_DETAILS_SUCCESS,
  FETCH_DASHBOARD_DETAILS_REQUEST,
} = require("./type");

const initialState = {
  isLoading: false,
  error: "",
  dashboardDetails: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardDetails: action.payload,
        error: "",
      };
    case FETCH_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        dashboardDetails: "",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
