import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  NavLink,
} from "reactstrap";

import { verifyOtpThunkAction } from "redux/user/action";
import { selectUser } from "redux/user/selector";

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectUser);
  const history = useHistory();

  const [otp, setOtp] = React.useState("");
  const navigateToHome = () => {
    history.replace("/auth/resetpassword");
  };
  const submitHadler = (e) => {
    e.preventDefault();

    if (otp === "") {
      toast.error("Please enter OTP.");
      return;
    }

    if (!/^\d{4}$/.test(otp)) {
      toast.error("Please enter valid OTP.");
      return;
    }

    dispatch(
      verifyOtpThunkAction(
        { code: otp, email: state.forgetEmail },
        navigateToHome
      )
    );
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-1">
          <h3 className="text-dark text-center mt-4">Verify OTP</h3>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup className="mb-2">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter OTP"
                    type="number"
                    required
                    name="otp"
                    min={1}
                    value={otp}
                    onChange={(e) => {
                      if (e.target.value.length <= 4) {
                        setOtp(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div className="text-center">
                <Button className="my-4" color="dark" type="submit">
                  Verify OTP
                </Button>
                <NavLink to="/auth/login" tag={Link}>
                  <small
                    className="nav-link-inner--text"
                    style={{ color: "#000000" }}
                  >
                    Sign in?
                  </small>
                </NavLink>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default VerifyOtp;
