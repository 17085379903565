import React from "react";

const Column = ({ title, name, sortedBy, onSortBy, direction }) => {
  const handleClick = () => onSortBy(name);

  return (
    <th scope="col">
      <button
        type="button"
        style={{
          border: "none",
          color: "#8898aa",
          background: "none",
          letterSpacing: "1px",
          fontWeight: "600",
        }}
        onClick={handleClick}
      >
        {title}{" "}
        {name === sortedBy && (
          <span data-direction={direction}>
            {direction === "DOWN" ? (
              <i className="fas fa-arrow-down ml-2"></i>
            ) : (
              <i className="fas fa-arrow-up ml-2"></i>
            )}
          </span>
        )}
      </button>
    </th>
  );
};
export default Column;
