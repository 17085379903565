import toast from 'react-hot-toast';
import { getAccessCodeListApi } from 'services/GenerateAccessCodeService';
import { updateWholesalerDeactiveStatusApi } from 'services/GenerateAccessCodeService';
import { postCatalogData } from 'services/GenerateAccessCodeService';
import { getWholesalersAccesCodeApi } from 'services/GenerateAccessCodeService';
import { updateWholesalerActiveStatusApi } from 'services/GenerateAccessCodeService';
import { generateAccessCodeManualApi } from 'services/GenerateAccessCodeService';
import { generateAccessCodeAutomaticApi } from 'services/GenerateAccessCodeService';
import {
  FETCH_ACCESS_CODE_FAILURE,
  FETCH_ACCESS_CODE_REQUEST,
  FETCH_ACCESS_CODE_SUCCESS,
  GENERATE_ACCESS_CODE_FAILURE,
  GENERATE_ACCESS_CODE_REQUEST,
  GENERATE_ACCESS_CODE_SUCCESS,
  GENERATE_ACCESS_CODE,
  GENERATE_MANUAL_ACCESS_CODE,
  FETCH_WHOLESALER_ACCESS_CODE_REQUEST,
  FETCH_WHOLESALER_ACCESS_CODE_SUCCESS,
  FETCH_WHOLESALER_ACCESS_CODE_FAILURE,
} from './type';

export const generateAccessCodeList = (data) => {
  return {
    type: GENERATE_ACCESS_CODE,
    payload: data,
  };
};
export const generateManualAccessCode = (data) => {
  return {
    type: GENERATE_MANUAL_ACCESS_CODE,
    payload: data,
  };
};

export const fetchAccessCodeRequest = () => {
  return {
    type: FETCH_ACCESS_CODE_REQUEST,
  };
};
export const fetchAccessCodeSuccess = (accessCodes) => {
  return {
    type: FETCH_ACCESS_CODE_SUCCESS,
    payload: { accessCodes },
  };
};
export const fetchAccessCodeFailure = (error) => {
  return {
    type: FETCH_ACCESS_CODE_FAILURE,
    payload: error,
  };
};

export const generateAccessCodeRequest = () => {
  return {
    type: GENERATE_ACCESS_CODE_REQUEST,
  };
};
export const generateAccessCodeSuccess = () => {
  return {
    type: GENERATE_ACCESS_CODE_SUCCESS,
  };
};
export const generateAccessCodeFailure = (error) => {
  return {
    type: GENERATE_ACCESS_CODE_FAILURE,
    payload: error,
  };
};

export const fetchWholaserAccessCodeRequest = () => {
  return {
    type: FETCH_WHOLESALER_ACCESS_CODE_REQUEST,
  };
};
export const fetchWholaserAccessCodeSuccess = (accessCodes) => {
  return {
    type: FETCH_WHOLESALER_ACCESS_CODE_SUCCESS,
    payload: { accessCodes },
  };
};
export const fetchWholaserAccessCodeFailure = (error) => {
  return {
    type: FETCH_WHOLESALER_ACCESS_CODE_FAILURE,
    payload: error,
  };
};

export const fetchAccessCodesThunkAction = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchAccessCodeRequest());
      const { data } = await getAccessCodeListApi();
      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(fetchAccessCodeSuccess(data.data));
    } catch (error) {
      dispatch(fetchAccessCodeFailure(error.message));
    }
  };
};

export const fetchWholesalerAccessCodesThunkAction = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchWholaserAccessCodeRequest());

      const { data } = await getWholesalersAccesCodeApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchWholaserAccessCodeSuccess(data.data));
    } catch (error) {
      dispatch(fetchWholaserAccessCodeFailure(error.message));
    }
  };
};

export const generateAccessCodeAutomaticThunkAction = ({ limit }, onUpdate) => {
  return async (dispatch) => {
    try {
      dispatch(generateAccessCodeRequest());
      const { data } = await generateAccessCodeAutomaticApi({ limit });

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onUpdate();
      toast.success('Access Code generated successfully.');
      dispatch(generateAccessCodeSuccess(data.data));
      // dispatch(generateAccessCodeList(data.data));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(generateAccessCodeFailure(error.message));
    }
  };
};

export const generateAccessCodeManualThunkAction = (
  { accessCode },
  onUpdate
) => {
  return async (dispatch) => {
    try {
      dispatch(generateAccessCodeRequest());
      const { data } = await generateAccessCodeManualApi({ accessCode });

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onUpdate();
      toast.success('Access Code generated successfully.');
      dispatch(generateAccessCodeSuccess(data.data));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(generateAccessCodeFailure(error.message));
    }
  };
};

export const activeWholesalerStatusThunkAction = (id, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await updateWholesalerActiveStatusApi(id);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const deactiveWholesalerStatusThunkAction = (id, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await updateWholesalerDeactiveStatusApi(id);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const catalogAccessCodeGenerateThunkAction = (
  id,
  reqBody,
  onSuccess
) => {
  return async (dispatch) => {
    try {
      console.log(id, reqBody);
      const promise = postCatalogData(id, reqBody);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Accesscode binded successfully.',
        error: (error) => `${error?.response?.data?.message || error?.message}`,
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onSuccess();
    }
  };
};
