import { downloadFile } from "components/downloadCsvFile/DownloadCSV";
import React from "react";

import "./style.css";

export default function AccessCodeTable(props) {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpander = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const { item, value } = props;

  return (
    <>
      <tr
        key="main"
        style={{
          cursor: "pointer",
        }}
        onClick={toggleExpander}
      >
        <td style={{ width: "10%" }}>{props.index}</td>
        <td style={{ width: "30%" }}>{item}</td>
        <td>
          <i className="fa fa-chevron-down" />
        </td>
      </tr>
      {expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="" colSpan={4}>
            <div className="inner">
              {value.map((code, index) => {
                return (
                  <div key={index}>
                    <span>{index + 1}</span>
                    <h5 className="mx-4 text-dark">{code}</h5>
                    {value.length !== index + 1 && <hr />}
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
