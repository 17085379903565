/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";

import { refreshState } from "redux/user/action";
import { selectUserToken } from "redux/user/selector";

import "./assets/css/App.css";
import NewCodeGeneration from "Pages/NewCodeGeneration";
import dotenv from "dotenv";

dotenv.config();

function App() {
  const dispatch = useDispatch();
  const token =
    useSelector(selectUserToken) || localStorage.getItem("adt_access_token");
  const refreshStateHandler = () => {
    const token = localStorage.getItem("adt_access_token");
    dispatch(refreshState({ token }));
  };

  useEffect(() => {
    refreshStateHandler();
  }, []);

  useEffect(() => {}, [token]);
  return (
    <>
      <BrowserRouter>
        <Switch>
        <Route path="/bindAccesscode" component={NewCodeGeneration} />
          {token ? (
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
          ) : (
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          )}
          {token ? (
            <Redirect from="/" exact to="/admin/dashboard" />
          ) : (
            <Redirect from="/" exact to="/auth/login" />
          )}

          <Route>
            {token ? (
              <Redirect to="/admin/notfound" />
            ) : (
              <Redirect to="/auth/notfound" />
            )}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
