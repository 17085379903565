/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Col,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "components/Headers/Header.jsx";
import { getColumns } from "components/TableColumn/Userscolumn";
import Paginate from "components/Pagination/Paginate";
import Search from "components/Search/Search";
import Loading from "components/Loading/Loading";
import SelectSearch from "components/SelectSearch/SelectSearch";
import { downloadFile } from "components/downloadCsvFile/DownloadCSV";

import { userSelectRoleOptions } from "Data/userList";

import { selectUserToken } from "redux/user/selector";
import { selectUser } from "redux/user/selector";
import { fetchUsersthunkAction } from "redux/user/action";
import { deactiveWholesalerStatusThunkAction } from "redux/AccessCode/action";
import { activeWholesalerStatusThunkAction } from "redux/AccessCode/action";

import "../assets/css/UsersPage.css";
import { fetchTotalUsersthunkAction } from "redux/user/action";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const UsersPage = () => {
  const [usersData, setUsersData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();

  const [searchFilter, setSearchFilter] = React.useState(null);

  const handleChange = (newValue) => {
    gotoPage(0);
    setSearchFilter(newValue);
  };

  const dispatch = useDispatch();
  const { users, isLoading, totalPage, totalUsers } = useSelector(selectUser);
  const token = useSelector(selectUserToken);
  React.useEffect(() => {
    setUsersData(users);
  }, [users, users?.length]);
  const onUpdateSuccess = () => {
    dispatch(
      fetchUsersthunkAction(
        {
          search: globalFilter || "",
          limit: pageSize,
          page: pageIndex,
          role: searchFilter?.value || "",
        },
        onSuccess,
        onError
      )
    );
    setSearchFilter(null);
    setGlobalFilter("");
  };

  const onSubmitStatusHandler = async (id, value) => {
    try {
      if (value === "active") {
        await dispatch(
          await activeWholesalerStatusThunkAction(id, onUpdateSuccess)
        );
      } else {
        await dispatch(
          await deactiveWholesalerStatusThunkAction(id, onUpdateSuccess)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  let history = useHistory();

  const navigateToUserDetailsPage = (id) => {
    history.push(`/admin/user/${id}/details`);
  };

  const data = React.useMemo(() => usersData, [usersData]);
  const columns = React.useMemo(
    () => getColumns(onSubmitStatusHandler, navigateToUserDetailsPage, index),
    [index]
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);


  React.useEffect(() => {
    if (token) {
      dispatch(
        fetchUsersthunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            role: searchFilter?.value || "",
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, searchFilter]);

  React.useEffect(() => {
    if (token) {
      dispatch(
        fetchTotalUsersthunkAction(
          {
            search: globalFilter || "",
            page: 0,
            role: searchFilter?.value || "",
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, token, searchFilter, globalFilter]);

  const exportToCsv = async () => {
    let headers = ["Name,Email,Mobile,Role,Email Verified,Status"];

    let usersCsv = await totalUsers.reduce((acc, user) => {
      const { firstName, email, mobile, role, status, isActive } = user;
      acc.push(
        [
          firstName,
          email,
          mobile,
          role.charAt(0).toUpperCase() + role.slice(1),
          status === true ? "Verified" : "Not Verified",
          role === "retailer" ? "Activated" : isActive ? "Active" : "Inactive",
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Users.csv",
      fileType: "text/csv",
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="12" md="6">
                    <h2 className="mb-0">Users</h2>
                  </Col>

                  <Col xs="12" md="2" className="mr-0">
                    <Button
                      onClick={exportToCsv}
                      style={{
                        backgroundColor: "dimgray",
                        color: "#fff",
                        // margin: "0 1rem ",
                        marginLeft: "1rem",
                        borderRadius: "0px",
                      }}
                    >
                      Export CSV
                    </Button>
                  </Col>
                  <Col xs="12" md="2" className="mr-0">
                    <SelectSearch
                      handleChange={handleChange}
                      placeHolder="Search by Role"
                      options={userSelectRoleOptions}
                      value={searchFilter}
                    />
                  </Col>
                  <Col xs="6" md="2" className="text-right">
                    <Search
                      keyword={globalFilter}
                      onSubmitHandler={onSubmitHandler}
                      setKeyword={setGlobalFilter}
                    />
                  </Col>
                </Row>
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loading />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table
                        {...getTableProps()}
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          {headerGroups.map((headerGroup) => (
                            <tr
                              {...headerGroup.getHeaderGroupProps()}
                              key={headerGroup}
                            >
                              {headerGroup.headers.map((column) => (
                                <th
                                  key={column.id}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                  <span>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="fas fa-arrow-down"></i>
                                      ) : (
                                        <i className="fas fa-arrow-up"></i>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} key={row.id}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {!error && usersData?.length === 0 && (
                        <div className="no_posts">No users found.</div>
                      )}
                      {usersData && usersData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <div className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                              <div>
                                <Input
                                  type="select"
                                  style={{
                                    width: "85px",
                                    margin: "10px",
                                  }}
                                  value={pageSize}
                                  onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                  }}
                                >
                                  {[5, 10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                      {pageSize}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="mx-2">
                                  Page{" "}
                                  <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                  </strong>{" "}
                                </span>
                                <Paginate
                                  pageIndex={pageIndex}
                                  pageCount={pageCount}
                                  previousPage={previousPage}
                                  nextPage={nextPage}
                                  gotoPage={gotoPage}
                                />
                              </div>
                            </div>
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UsersPage;
