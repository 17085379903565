import TooltipIcon from 'components/TooltipIcon/TooltipIcon';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';

const phoneFormat = (input) => {
  if (input?.length === 10) {
    return input?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return 'Invalid phone number';
};

const capilatize = (str) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const getColumns = (
  onSubmitStatusHandler,
  navigateToUserDetailsPage,
  index
) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${index + row.index + 1}`;
      },
    },
    {
      Header: 'name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => navigateToUserDetailsPage(row?.original?.id)}
            style={{
              cursor: 'pointer',
            }}
          >
            {row?.original?.firstName}
          </div>
        );
      },
    },
    {
      Header: 'email',
      accessor: 'email',
      Cell: ({ row }) => {
        return row.original.isDeleted ? (
          <div
            onClick={() => navigateToUserDetailsPage(row?.original?.id)}
            style={{
              cursor: 'pointer',
            }}
          >
            {row?.original?.email.split('-')[0]}
          </div>
        ) : (
          <div
            onClick={() => navigateToUserDetailsPage(row?.original?.id)}
            style={{
              cursor: 'pointer',
            }}
          >
            {row?.original?.email}
          </div>
        );
      },
    },

    {
      Header: 'mobile',
      accessor: 'mobile',
      Cell: ({ row }) => {
        return row.original.isDeleted ? (
          <div
            onClick={() => navigateToUserDetailsPage(row?.original?.id)}
            style={{
              cursor: 'pointer',
            }}
          >
            {phoneFormat(row?.original?.mobile.split('-')[0])}
          </div>
        ) : (
          <div
            onClick={() => navigateToUserDetailsPage(row?.original?.id)}
            style={{
              cursor: 'pointer',
            }}
          >
            {phoneFormat(row?.original?.mobile)}
          </div>
        );
      },
    },
    {
      Header: 'role',
      accessor: 'role',
      Cell: ({ row }) => {
        return capilatize(row?.original?.role);
      },
    },
    {
      Header: 'email verified',
      accessor: 'status',
      Cell: ({ row }) => {
        return row.original.status ? (
          <Badge color="success" pill>
            Verified
          </Badge>
        ) : (
          <Badge color="danger" pill>
            Not Verified
          </Badge>
        );
      },
    },
    {
      Header: 'status',
      accessor: 'isActive',
      Cell: ({ row }) => {
        return row.original.isDeleted ? (
          <>
            <div>
              <Badge color="danger" pill>
                Deleted
              </Badge>
            </div>
          </>
        ) : (
          <>
            {row.original.isActive ? (
              <>
                <div id="tooltip734051587">
                  <TooltipIcon
                    // target={"Approve" + row?.original?.id}
                    label="Active"
                    iconClass="fas fa-check-circle fa-lg mr-2"
                    onclickHandler={() =>
                      onSubmitStatusHandler(row.original.id, 'deactive')
                    }
                    role={row.original.role}
                  />
                  {row.original.role !== 'retailer' ||
                  row.original.isDeleted ? (
                    <UncontrolledTooltip
                      innerClassName="bg-muted"
                      delay={0}
                      placement="left"
                      target="tooltip734051587"
                    >
                      Deactivate Wholesaler
                    </UncontrolledTooltip>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div id="tooltip734051588">
                  <TooltipIcon
                    // target={"Reject" + row?.original?.id}
                    label="Inactive"
                    iconClass="fas fa-times-circle fa-lg mr-2"
                    onclickHandler={() =>
                      onSubmitStatusHandler(row.original.id, 'active')
                    }
                    role={row.original.role}
                  />
                  <UncontrolledTooltip
                    innerClassName="bg-muted"
                    delay={0}
                    placement="left"
                    target="tooltip734051588"
                  >
                    Activate Wholesaler
                  </UncontrolledTooltip>
                </div>
              </>
            )}
          </>
        );
      },
    },
  ];
};
