import React from "react";

import { useSelector } from "react-redux";

import { selectUserToken } from "redux/user/selector";

import Error from "../Pages/Error";

// eslint-disable-next-line max-len
const Authorization = () => (WrappedComponent) =>
  function WithAuthorization() {
    const token =
      useSelector(selectUserToken) || localStorage.getItem("adt_access_token");

    if (token) {
      return <WrappedComponent />;
      // eslint-disable-next-line no-else-return
    }

    return (
      <Error error="403 Forbidden" message="Please login to see this page!" />
    );
  };

export default Authorization;
