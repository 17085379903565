import axiosInstance from "./AxiosInstance";
import moment from "moment";

export const getDashboardDetailsApi = (prms) => {
  if (prms.start_date !== null && prms.end_date !== null) {
    return axiosInstance.get("users/dashboard", {
      params: {
        start_date: moment
          .utc(moment(prms?.start_date).startOf("day"))
          .format(),
        end_date: moment.utc(moment(prms?.end_date).endOf("day")).format(),
      },
    });
  } else {
    return axiosInstance.get("users/dashboard", {
      params: {
        start_date: null,
        end_date: null,
      },
    });
  }
};
