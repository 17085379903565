import moment from 'moment';
import { Badge } from 'reactstrap';

export const getColumns = (index) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${index + row.index + 1}`;
      },
    },
    {
      Header: 'User Name',
      accessor: 'firstName',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <div>{row?.original?.orderBy?.firstName}</div>;
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <div>{row?.original?.catalog?.title}</div>;
      },
    },
    {
      Header: 'Purchase On',
      accessor: 'createdAt',
      Cell: ({ row }) => {
        return (
          <div>
            {moment(row?.original?.createdAt).format('MM/DD/YYYY [at] h:mm A')}
          </div>
        );
      },
    },
    {
      Header: 'Expires On',
      accessor: 'expirationDate',
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.expirationDate
              ? moment(row?.original?.expirationDate).format(
                  'MM/DD/YYYY [at] h:mm A'
                )
              : '-'}
          </div>
        );
      },
    },
    {
      Header: 'Transaction Id',
      accessor: 'transactionId',
      disableSortBy: true,
    },
    {
      Header: 'Transaction Amount',
      accessor: 'transactionAmount',
      Cell: ({ row }) => {
        return (
          <div>
            ${' '}
            {row?.original?.transactionAmount
              .toFixed(2)
              .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
          </div>
        );
      },
    },
    {
      Header: 'Quantity',
      accessor: 'qty',
      Cell: ({ row }) => {
        return <span>{row.original?.qty ? row?.original?.qty : '-'}</span>;
      },
    },
    {
      Header: 'Status',
      accessor: 'isExpired',
      Cell: ({ row }) => {
        return row.original.isExpired ? (
          <Badge color="danger" pill>
            Expired
          </Badge>
        ) : (
          <Badge color="success" pill>
            Active
          </Badge>
        );
      },
    },
  ];
};
