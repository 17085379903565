/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import debounce from "lodash.debounce";

const Search = ({ onSubmitHandler, keyword, setKeyword }) => {
  const [value, setValue] = React.useState('');

  // highlight-starts
  const debouncedSave = React.useCallback(
    debounce((nextValue) => setKeyword(nextValue), 200),
    [] // will be created only once initially
  );
  // highlight-ends

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue);
  };


  React.useEffect(() => {
    if(keyword === undefined){
      setValue('')
    }
  }, [keyword])
  

  return (
    <>
      <Form
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler(keyword);
        }}
      >
        <FormGroup className="d-flex mb-0">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-zoom-split-in" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search"
              type="text"
              value={value || ""}
              onChange={handleChange}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};

export default Search;
