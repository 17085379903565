import React from "react";

import copy from "copy-to-clipboard";
import { UncontrolledTooltip } from "reactstrap";

import "./style.css";

export default function AccessCodeTable(props) {
  const [expanded, setExpanded] = React.useState(false);

  const [toolTip, setToolTip] = React.useState("Click To Copy Code ");

  const copyToClipboard = (item) => {
    copy(item);
    setToolTip("Code Copied ");
  };

  const toggleExpander = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      setToolTip("Click To Copy Code ");
    }, 600);
  }, [toolTip]);

  const { item, value } = props;
  return [
    <tr
      key="main"
      style={{
        cursor: "pointer",
      }}
      onClick={toggleExpander}
    >
      <td style={{ width: '10%' }}>{props.index}</td>
      <td style={{ width: '30%' }}>{item}</td>
      <td>
        <i className="fa fa-chevron-down" />
      </td>
    </tr>,
    expanded && (
      <tr className="expandable" key="tr-expander">
        <td className="uk-background-muted" colSpan={6}>
          <div className="inner uk-grid">
            {value.accessCodes.map((items, index) => {
              return (
                <div key={index}>
                  <span>{index + 1}</span>
                  <h5
                    id="tooltip734051587"
                    onClick={(item) => copyToClipboard(item.target.innerText)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {items}
                  </h5>
                  {
                    value.accessCodes.length !== index + 1 && (
                      <hr/>
                    )
                  }
                  <UncontrolledTooltip
                    innerClassName="bg-primary"
                    delay={0}
                    placement="right"
                    target="tooltip734051587"
                  >
                    {toolTip}
                  </UncontrolledTooltip>
                </div>
              );
            })}
          </div>
        </td>
      </tr>
    ),
  ];
}
