import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardColumns,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';

import GoBackButton from 'components/GobackButton/GoBackButton';
import Header from 'components/Headers/Header';

import { userDetailThunkAction } from 'redux/user/action';
import { selectUser } from 'redux/user/selector';

import moment from 'moment';
import Loading from 'components/Loading/Loading';
import getSortedData from 'components/OrderDetailTable/getSortedData';
import Column from 'components/OrderDetailTable/Column';
import { downloadFile } from 'components/downloadCsvFile/DownloadCSV';

const phoneFormat = (input) => {
  if (input?.split('-')[0]?.length === 10) {
    return input?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return 'Invalid phone number';
};

const capilatize = (str) => {
  return str?.charAt(0).toUpperCase() + str?.substring(1);
};

const UserDetails = () => {
  const [userData, setUserData] = React.useState(null);
  let { id } = useParams();
  const dispatch = useDispatch();
  const { selecteduser, isLoading } = useSelector(selectUser);
  React.useEffect(() => {
    if (id) {
      dispatch(userDetailThunkAction(id));
    }
  }, [id, dispatch]);

  React.useEffect(() => {
    if (selecteduser) {
      setUserData(selecteduser);
    }
  }, [selecteduser]);
  const [sortedBy, setSortedBy] = useState(null);
  const [direction, setDirection] = useState('UP');

  const onSortBy = (name) => {
    if (sortedBy !== name) {
      setSortedBy(name);
    } else if (direction === 'UP') {
      setDirection('DOWN');
    } else {
      // setSortedBy(null);
      setDirection('UP');
    }
  };

  const exportToCsv = async () => {
    let headers = [
      'Title,Purchased On,Expires On,Transaction Id,Rate/Qty,Quantity,Transaction Amount,Status',
    ];
    let usersCsv = await userData?.orders.reduce((acc, user) => {
      const {
        catalog,
        createdAt,
        expirationDate,
        transactionId,
        amount,
        qty,
        transactionAmount,
        isExpired,
      } = user;
      acc.push(
        [
          catalog.title,
          moment(createdAt).format('MM/DD/YYYY [at] h:mm A'),
          moment(expirationDate).format('MM/DD/YYYY [at] h:mm A'),
          transactionId,
          '$ ' + amount,
          qty,
          '$ ' + transactionAmount,
          isExpired ? 'Expired' : 'Active',
        ].join(',')
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `${userData?.firstName}.csv`,
      fileType: 'text/csv',
    });
  };

  const sortedData = getSortedData(userData?.orders, sortedBy, direction);

  console.log(sortedData);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 " xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h2 className="mb-0">User Details</h2>
                  </Col>

                  <Col className="text-right">
                    <GoBackButton />
                  </Col>
                </Row>
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading mb-5">
                    <Loading />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CardBody className="bg-white">
                    <div className="pl-lg-4">
                      <div className="row mb-2">
                        <label className="col-lg-2 font-weight-bold text-muted">
                          Full Name :
                        </label>
                        <div className="col-lg-8">
                          <span className="text-dark font-weight- text-capitalize">
                            {userData?.firstName}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-lg-2 font-weight-bold text-muted">
                          Email :
                        </label>
                        <div className="col-lg-8">
                          <span className="text-dark ">
                            {userData?.email?.split('-')[0]}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-lg-2 font-weight-bold text-muted">
                          Mobile :
                        </label>
                        <div className="col-lg-8">
                          <span className="text-dark ">
                            {phoneFormat(userData?.mobile?.split('-')[0])}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-lg-2 font-weight-bold text-muted">
                          Role :
                        </label>
                        <div className="col-lg-8">
                          <span className="text-dark ">
                            {capilatize(userData?.role)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {userData?.orders.length ? (
                      <row>
                        <CardHeader className="bg-white border-0">
                          <Row className="align-items-center">
                            <Col xs="10">
                              <h3 className="mb-0">Order Details</h3>
                            </Col>
                            <Button
                              onClick={exportToCsv}
                              style={{
                                backgroundColor: 'dimgray',
                                color: '#fff',
                                // margin: "0 1rem ",
                                marginLeft: '1rem',
                                borderRadius: '0px',
                              }}
                            >
                              Export CSV
                            </Button>
                          </Row>
                        </CardHeader>
                      </row>
                    ) : null}

                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Title</th>
                          <Column
                            name="createdAt"
                            title="PURCHASED ON"
                            direction={direction}
                            sortedBy={sortedBy}
                            onSortBy={onSortBy}
                          />
                          <Column
                            name="expirationDate"
                            title="EXPIRES ON"
                            direction={direction}
                            sortedBy={sortedBy}
                            onSortBy={onSortBy}
                          />
                          <th scope="col">Transaction Id</th>
                          <Column
                            name="amount"
                            title="RATE/QTY"
                            direction={direction}
                            sortedBy={sortedBy}
                            onSortBy={onSortBy}
                          />
                          <Column
                            name="qty"
                            title="QUANTITY"
                            direction={direction}
                            sortedBy={sortedBy}
                            onSortBy={onSortBy}
                          />
                          <Column
                            name="transactionAmount"
                            title="TRANSACTION AMOUNT"
                            direction={direction}
                            sortedBy={sortedBy}
                            onSortBy={onSortBy}
                          />
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData?.length
                          ? sortedData.map((orders) => {
                              return (
                                <tr>
                                  <td>{orders?.catalog?.title}</td>
                                  <td>
                                    {moment(orders?.createdAt).format(
                                      'MM/DD/YYYY [at] h:mm A'
                                    )}
                                  </td>
                                  <td>
                                    {orders?.expirationDate
                                      ? moment(orders?.expirationDate).format(
                                          'MM/DD/YYYY [at] h:mm A'
                                        )
                                      : '-'}
                                  </td>
                                  <td>{orders?.transactionId}</td>
                                  <td>$ {orders?.amount}</td>
                                  <td>{orders?.qty ? orders?.qty : '-'}</td>
                                  <td>
                                    ${' '}
                                    {orders?.transactionAmount
                                      .toFixed(2)
                                      .replace(
                                        /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
                                        ','
                                      )}
                                  </td>
                                  <td>
                                    {orders?.isExpired ? (
                                      <Badge color="danger" pill>
                                        Expired
                                      </Badge>
                                    ) : (
                                      <Badge color="success" pill>
                                        Active
                                      </Badge>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>

                    {userData?.orders?.length === 0 && (
                      <div className="no_posts">No Data found.</div>
                    )}
                  </CardBody>
                </React.Fragment>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserDetails;
