import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};

const Paginate = ({
  pageCount,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage,
}) => {
  const fetchPageNumbers = () => {
    const totalPages = pageCount;
    const currentPage = pageIndex + 1;
    const pageNeighbours = 1;
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2; //Math.min(totalNumbers, pageCount);

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };
  const pages = fetchPageNumbers();

  return (
    <>
      <React.Fragment>
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          {pages.map((page) => {
            if (page === LEFT_PAGE)
              return (
                <PaginationItem key={page}>
                  <PaginationLink onClick={() => previousPage()}>
                    <i className="fas fa-lg fa-angle-left"></i>
                  </PaginationLink>
                </PaginationItem>
              );
            if (page === RIGHT_PAGE)
              return (
                <PaginationItem key={page}>
                  <PaginationLink onClick={() => nextPage()}>
                    <i className="fas fa-lg fa-angle-right" />
                  </PaginationLink>
                </PaginationItem>
              );
            return (
              <PaginationItem
                key={page}
                className={page - 1 === pageIndex ? "active" : ""}
              >
                <PaginationLink onClick={() => gotoPage(page - 1)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
        </Pagination>
      </React.Fragment>
    </>
  );
};

export default Paginate;
