import React from "react";
import ReactDOM from "react-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import store from "redux/store";
import App from "App";

ReactDOM.render(
  <Provider store={store}>
    <Toaster position="top-center" gutter={10} />
    <App />
  </Provider>,
  document.getElementById("root")
);
