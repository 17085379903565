import axiosInstance from "./AxiosInstance";

export const getLoggedInUserApi = (userData) =>
  axiosInstance.post("auth/admin/login", userData);

export const forgotPasswordUserApi = (userData) =>
  axiosInstance.post("auth/forgot-password", userData);

export const verifyOtpUserApi = (userData) =>
  axiosInstance.post("auth/verify-otp", userData);

export const resetPasswordUserApi = (userData) =>
  axiosInstance.post("auth/reset-password", userData);

export const loggedOutUserApi = () => axiosInstance.delete("auth/logout");

export const changePasswordUserApi = (userData) =>
  axiosInstance.post("users/change-password", userData);

export const getUsersListApi = (prms) =>
  axiosInstance.get("users/list", {
    params: {
      limit: prms.limit,
      page: prms?.page || 0,
      search: prms?.search || "",
      role: prms.role || "",
    },
  });

export const userDetailApi = (userId) =>
  axiosInstance.get(`users/${userId}/details`);
