const {
  FETCH_ACCESS_CODE_REQUEST,
  FETCH_ACCESS_CODE_SUCCESS,
  FETCH_ACCESS_CODE_FAILURE,
  GENERATE_ACCESS_CODE_REQUEST,
  GENERATE_ACCESS_CODE_SUCCESS,
  GENERATE_ACCESS_CODE_FAILURE,
  GENERATE_ACCESS_CODE,
  GENERATE_MANUAL_ACCESS_CODE,
  FETCH_WHOLESALER_ACCESS_CODE_REQUEST,
  FETCH_WHOLESALER_ACCESS_CODE_SUCCESS,
  FETCH_WHOLESALER_ACCESS_CODE_FAILURE,
} = require("./type");

const initialState = {
  isLoading: false,
  error: "",
  accessCodes: [],
  wholesalerAccessCodes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCESS_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accessCodes: action.payload.accessCodes,
        error: "",
      };

    case FETCH_ACCESS_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        accessCodes: "",
        error: action.payload,
      };

    case GENERATE_ACCESS_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GENERATE_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };

    case GENERATE_ACCESS_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GENERATE_ACCESS_CODE:
      return {
        ...state,
        accessCodes: action.payload,
      };

    case GENERATE_MANUAL_ACCESS_CODE:
      return {
        ...state,
        accessCodes: action.payload,
      };
    case FETCH_WHOLESALER_ACCESS_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_WHOLESALER_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wholesalerAccessCodes: action.payload.accessCodes,
        error: "",
      };

    case FETCH_WHOLESALER_ACCESS_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        wholesalerAccessCodes: "",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
