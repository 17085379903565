import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
} from "reactstrap";

import Header from "components/Headers/Header";
import { selectUserToken } from "redux/user/selector";

const Error = ({ error, message }) => {
  const token =
    useSelector(selectUserToken) || localStorage.getItem("adt_access_token");

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardBody style={{ height: "100%" }}>
                <div className="big_text text">
                  <h1>
                    4<span className="color_blue">0</span>4
                  </h1>
                  <span>
                    <CardText>{message}</CardText>
                  </span>
                  {token ? (
                    <NavLink to="/admin/dashboard" exact>
                      Go back to home page
                    </NavLink>
                  ) : (
                    <NavLink to="/auth/login" exact>
                      Go back to Login page
                    </NavLink>
                  )}
                </div>
              </CardBody>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Error;
